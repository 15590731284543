.bottom-button{
    display: flex;
    align-self: flex-end;
}

.margin-button{
    margin: 3px
}


