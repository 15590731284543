.center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.right-margen{
    margin-right: 15%;
}
.right-down-div{
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.a_compact_message {
    width: 50em ;
}
