
.DevicePageQRCode {
    display: flex;
    margin: auto;
    margin-top: 3em;
    justify-content: center;
}

.DevicePageHeader {
    background-color: white;
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top:1em;
    margin-bottom:1em;
}


.DevicePageEditOption {
    display: flex;
    justify-content: flex-end;
}