.columns-states{
    margin-top: 2%;
    padding: 1%;
    border-radius: 2%;
    width: 24%;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.destination-column {
    transition: background-color 0.2s ease;
    background-color: #dddcdb;
    margin-top: 2%;
    padding: 1%;
    border-radius: 2%;
    width: 24%;
    display: flex;
    flex-direction: column;

}
.order-description-field {
    width: 100%;
    max-width: 100%;
}

.order-card{
    padding: 2%;
    margin: 4%;
    cursor: pointer;
}

.states-list {
    display: flex;
    justify-content: space-between;
}