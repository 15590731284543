
.WarningRow {
    background-color: #dddcdb;
    color: #6D6058;
}

.BrokenComputerRow {
    background-color: #F0EFEE;
    color: #6D6058;
}

.AppBackground {
    background-color: #D0CCC9;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.SegmentColor {
    background-color: #DDDAD8;
}


.fields-row-form-container {
    display: flex;
    align-items: center
}

.fields-row-form-container > .SecondaryTrash{
    top:0
}

.SecondaryTrash{
    position :relative;
    top: 33px;
    cursor: pointer;
}

.Overflow {
    overflow-x: scroll;
}

.EditComputerBackground {
    background-color: #ffffff;
}
