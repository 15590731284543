.App {
    text-align: center;
}
.App-link {
    color: #61dafb;
}

main {
    flex-grow: 1;
    padding-top: 40px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
